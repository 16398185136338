import React from "react"
import { Link } from "gatsby"

import Layout from "../mainComponents/Layout"
import SEO from "../mainComponents/seo"
import Wrapper from "../mainComponents/Wrapper"

import "../style/notfound.scss"

import CatNotFound from "../assets/cat-notfound.svg"

const NotFoundPage = () => (
  <Wrapper>
  <Layout notfoundClass="notfoundClass">
    <SEO title="404: Not found" description={"This is a 404 page, nothing special."} preventIndex={true}/>

    <section className="notfound">
      <div className="notfound__wrapper">
        <img className="notfound__image" src={CatNotFound} alt=""/>

        <p className="notfound__text">Our cats sniffed this page well but didn’t find anything.{"\n"}Maybe better search on the main?</p>

        <Link className="notfound__link" to="/">Return to main</Link>
      </div>
    </section>
  </Layout>
  </Wrapper>
)

export default NotFoundPage
